<template>
  <div
    class="modal modal-large no-overflow show"
    :class="{ 'modal-success': showOptions.state == 'success' }"
  >
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Modal Body-->
    <div class="modal-body modal-header-body">
      <div class="internal-modal-body">
        <!--START: Title-->
        <div
          class="title-wrapper"
          :class="{ 'show-shadow': showOptions.headerShadow }"
        >
          <div class="title-info">
            <h3 class="title">{{ modalContent.title }}</h3>
            <p>{{ modalContent.description }}</p>
          </div>
          <div class="actions-wrapper">
            <button
              class="btn btn-small btn-primary"
              :disabled="showOptions.disableButton"
              @click="saveIssue"
            >
              Save
            </button>
          </div>
        </div>
        <!--END: Title-->

        <div ref="scrollBody" class="scroll-body" @scroll="bodyScroll">
          <FormBuilder
            :fields="fields"
            class="form-builder-main"
            @fieldChanged="fieldChanged"
          ></FormBuilder>
        </div>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader" class="bottom"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                
<script>
// Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import FormBuilder from "@/components/form/FormBuilder";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";

// Importing Services
import { WidgetSettingsService } from "@/services";

export default {
  name: "AddIssue",
  props: {
    issue: { type: Object, required: true },
    categories: { type: Array, required: true },
  },
  watch: {},
  computed: {},
  data() {
    return {
      title: "Add Issue",
      modalContent: {
        title: "Add Issue",
        description: "Add an issue to help customers navigate",
      },
      showOptions: {
        state: "form",
        disableButton: true,
        headerShadow: false,
        lineLoader: false,
      },
      fields: {
        question: {
          type: "text",
          placeholder: "Add a title",
          required: true,
          hasError: false,
          title: "Question title*",
          value: "",
        },
        category: {
          type: "dropdown",
          placeholder: "Select category",
          title: "Category for the question*",
          disabled: false,
          required: true,
          hasError: false,
          fields: [],
          value: [],
        },
        answer: {
          type: "textarea",
          title: "Answer to a particular issue",
          placeholder: "Type a message",
          required: false,
          hasError: false,
          value: "",
        },
      },
      issueID: null,
      initData: {},
      status: {
        show: false,
        status: "success",
        title: "Issue saved",
      },
    };
  },
  components: {
    ModalHeader,
    FormBuilder,
    LineLoader,
    NotificationMessage,
  },
  created() {
    this.initModal();
    this.populateData();
  },

  methods: {
    initModal() {
      this.issueID = this.issue?.issue?._id;

      const categories = [{ name: "None", value: null }];
      this.categories.forEach((c) => {
        categories.push({ name: c, value: c });
      });

      this.fields.category.fields = _.cloneDeep(categories);

      this.initFormData();
    },

    populateData() {
      if (this.issueID) {
        const { issue, issues } = this.issue;
        this.fields.question.value = issue.question;
        this.fields.answer.value = issue.answer;

        let selectedCategory = ["None", null];
        if (issue.category) selectedCategory = [issue.category, issue.category];
        this.fields.category.value = selectedCategory;

        if (issues.length > 0) this.fields.category.disabled = true;
      }
    },

    async saveIssue() {
      if (!this.showOptions.lineLoader) {
        this.showOptions.lineLoader = false;
        const data = { ...this.fields };
        var isFormValid = this.validateForm(data);

        if (isFormValid) {
          this.showOptions.lineLoader = true;
          const payload = this.constructPayload();

          const response = await WidgetSettingsService.SaveOrderIssue(payload);

          if (!response.hasError) {
            this.questionID = response.data._id;
            this.showStatusMessage(this.status, 2500);
            this.$emit("issueUpdated", response.data);
            this.initFormData();
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
      }
    },

    // Construct the payload for the controller
    constructPayload() {
      const data = {
        issueID: this.issueID,
        ...this.parseData(this.fields),
      };

      return data;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = { ...this.fields };
      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Event when field changed
    fieldChanged() {
      const data = { ...this.fields };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.showOptions.disableButton = status;
    },

    bodyScroll() {
      const scrollTop = this.$refs.scrollBody.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
                
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.btn-add {
  float: right;
  margin: 0.5rem 0;
}

.message-fields-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;

  .message-field-form {
    flex: 1;
  }

  .message-actions {
    transform: translateY(-0.25rem);
    .unicon /deep/ {
      svg {
        cursor: pointer;
        height: auto;
        width: 0.75rem;
        fill: $greyColor;
        margin-left: 0.5rem;
      }
    }
  }
}

.form-builder-main {
  /deep/ .field-block-item {
    .field-main-item:last-child {
      border-bottom: 1px solid $inputBorderColor;
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }
}
</style>