<template>
  <div
    class="modal modal-large no-overflow show"
    :class="{ 'modal-success': showOptions.state == 'success' }"
  >
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Modal Body-->
    <div class="modal-body modal-header-body">
      <div class="internal-modal-body">
        <!--START: Title-->
        <div
          class="title-wrapper"
          :class="{ 'show-shadow': showOptions.headerShadow }"
        >
          <div class="title-info">
            <h3 class="title">{{ modalContent.title }}</h3>
            <p>{{ modalContent.description }}</p>
          </div>
          <div class="actions-wrapper">
            <button
              class="btn btn-small btn-primary"
              :disabled="showOptions.disableButton"
              @click="saveIssue"
            >
              Save
            </button>
          </div>
        </div>
        <!--END: Title-->

        <div ref="scrollBody" class="scroll-body" @scroll="bodyScroll">
          <!--START: Conditions-->
          <div v-if="showOptions.conditions" class="conditions-wrapper">
            <div class="block-info">
              <h3 class="title">Conditions</h3>
              <p class="description">
                Add conditions which qualify orders or products for this action
              </p>
            </div>
          </div>
          <!--END: Conditions-->

          <!--START: Reasons-->
          <div v-if="showOptions.reasons" class="reasons-wrapper">
            <div class="block-info">
              <h3 class="title">Reasons</h3>
              <p class="description">
                Ask the customer for reasons behind the action
              </p>
            </div>
            <FormBuilder
              :fields="reasonsFields"
              class="form-builder-main"
              @fieldChanged="fieldChanged"
            ></FormBuilder>

            <!--START: Reasons List-->
            <div v-if="reasonsFields.isActive.value" class="reasons-list">
              <div class="field-title">
                <label>Reasons for the customers to select from</label>
              </div>
              <div class="reasons-list-wrapper">
                <div
                  v-for="(reason, index) in reasons"
                  :key="index"
                  class="reason-item"
                >
                  <FormBuilder
                    :fields="{ reason }"
                    class="form-builder-main"
                    @fieldChanged="fieldChanged"
                  ></FormBuilder>
                  <div v-if="reasons.length > 0" class="actions">
                    <unicon
                      class="btn-delete"
                      name="trash-alt"
                      @click="deleteReason(index)"
                    ></unicon>
                  </div>
                </div>
                <button
                  class="btn btn-text btn-small btn-icon btn-add"
                  @click="addReason"
                >
                  <unicon name="plus"></unicon>
                  <span>Add Reason</span>
                </button>
              </div>
            </div>
            <!--END: Reasons List-->
          </div>
          <!--END: Reasons-->
        </div>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader" class="bottom"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
                  
<script>
// Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import FormBuilder from "@/components/form/FormBuilder";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";

export default {
  name: "ActionRules",
  props: {
    selectedAction: { type: String, required: true },
    customerAccountPortal: { type: Object, required: true },
  },
  watch: {},
  computed: {},
  data() {
    return {
      title: "Action Rules",
      showOptions: {
        conditions: false,
        reasons: false,
        lineLoader: false,
      },
      modalContent: {
        title: "Action Rules",
        description:
          "Use these rules to determine when and how the action works",
      },

      reasonsFields: {
        isActive: {
          type: "toggle",
          title: "Ask customers to give a reason",
          additionalClass: "large-toggle",
          value: false,
        },
        showOtherOption: {
          type: "toggle",
          title: "Show 'other' option",
          additionalClass: "large-toggle",
          value: false,
          show: false,
        },
      },
      reasonField: {
        type: "text",
        placeholder: "Add a reason",
        required: true,
        hasError: false,
        additionalClass: "small-input",
        value: "",
      },
      reasons: [],

      cancelOrder: {
        hasReasons: true,
        conditions: {
          isActive: true,
          conditionTypes: ["customer", "order", "product", "shipping"],
        },
      },

      initData: {},
      status: {
        show: false,
        status: "success",
        title: "Action saved",
      },
    };
  },
  components: {
    ModalHeader,
    FormBuilder,
    LineLoader,
    NotificationMessage,
  },
  created() {
    this.initModal();
    this.populateData();
  },

  methods: {
    initModal() {
      switch (this.selectedAction) {
        case "cancelOrder":
          this.modalContent.title = "Cancel Order Rules";
          this.showOptions.reasons = this.cancelOrder.hasReasons;
          this.showOptions.conditions = this.cancelOrder.conditions.isActive;
          break;
        default:
          break;
      }

      this.initFormData();
    },

    populateData() {},

    async saveIssue() {},

    // Add a reason
    addReason() {
      const reason = _.cloneDeep(this.reasonField);
      this.reasons.push(reason);
    },

    // Delete a reason
    deleteReason(index) {
      this.reasons.splice(index, 1);
    },

    // Construct the payload for the controller
    constructPayload() {
      const data = {
        issueID: this.issueID,
        ...this.parseData(this.fields),
      };

      return data;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = { ...this.fields };
      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Reasons field handler
    reasonsFieldHandler() {
      this.reasonsFields.showOtherOption.show =
        this.reasonsFields.isActive.value;
    },

    // Event when field changed
    fieldChanged() {
      this.reasonsFieldHandler();
      const data = { ...this.fields };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.showOptions.disableButton = status;
    },

    bodyScroll() {
      const scrollTop = this.$refs.scrollBody.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
                  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.btn-add {
  float: right;
  margin: 0.5rem 0;
}

.message-fields-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;

  .message-field-form {
    flex: 1;
  }

  .message-actions {
    transform: translateY(-0.25rem);
    .unicon /deep/ {
      svg {
        cursor: pointer;
        height: auto;
        width: 0.75rem;
        fill: $greyColor;
        margin-left: 0.5rem;
      }
    }
  }
}

.block-info {
  margin-bottom: 1.5rem;

  .title {
    font-size: $mediumFontSize;
  }

  .description {
    font-size: $smallFontSize;
  }
}

.form-builder-main {
  /deep/ .field-block-item {
    .field-main-item:last-child {
      border-bottom: 1px solid $inputBorderColor;
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }
}

.reasons-wrapper {
  /deep/ .field-main-item {
    border-bottom: none !important;
  }

  .reasons-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .field-title {
      flex: 1;
      color: $blackColor;
      font-weight: $mediumFontWeight;
      opacity: $fullOpacity;
    }

    .reasons-list-wrapper {
      width: 40%;
    }

    .btn-add {
      float: right;
    }
  }
}

.reason-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;

  /deep/ {
    .form-builder-main {
      flex: 1;
    }

    .field-main-item {
      border-bottom: none !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .btn-delete {
    cursor: pointer;
    margin-left: 0.5rem;

    /deep/ svg {
      fill: $lightGreyColor;
      height: auto;
      width: 1.15rem;
    }

    &:hover {
      /deep/ svg {
        fill: $redColor;
      }
    }
  }
}
</style>