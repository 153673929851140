<template>
  <div>
    <NestedDraggable
      :issues="issues"
      @issueSelected="showIssue"
      @deleteIssue="confirmIssueDelete"
      @itemsMoved="constructOrderIssues"
    ></NestedDraggable>
    <button class="btn btn-text btn-small btn-no-padding" @click="addIssue">
      <unicon name="plus"></unicon>
      <span>Add Issue</span>
    </button>

    <!--START: Add Issue modal-->
    <ViewIssue
      v-if="showOptions.addIssue"
      :issue="selectedIssue"
      :categories="categories"
      @issueUpdated="issueUpdated"
      @closeModal="closeModal"
    ></ViewIssue>
    <!--END: Add Issue modal-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
  
<script>
// Import components
import NestedDraggable from "./NestedDraggable";
import ViewIssue from "./ViewIssue";
import ConfirmationModal from "@/components/modals/ConfirmationModal";

// Importing Services
import { WidgetSettingsService } from "@/services";

export default {
  name: "AccountIssues",
  data() {
    return {
      initComplete: false,
      showOptions: {
        addIssue: false,
      },
      selectedIssue: {},
      issues: [],
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message: "This action cannot be undone. This rule will be removed.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  props: { orderIssues: Array },
  watch: {
    orderIssues: {
      immediate: true,
      deep: true,
      handler() {
        this.initIssues();
      },
    },
  },
  components: {
    NestedDraggable,
    ConfirmationModal,
    ViewIssue,
  },
  computed: {
    categories() {
      const response = [];
      const { issue: selectedIssue } = this.selectedIssue;

      this.issues.forEach((i) => {
        if (selectedIssue?.issue !== i.issue.question)
          response.push(i.issue.question);
      });

      return response;
    },
  },
  async created() {},
  methods: {
    // Initialise the issues
    initIssues() {
      this.issues.splice(0);

      const categoryIssues = [];
      this.orderIssues.forEach((i) => {
        if (!i.category) this.issues.push({ issue: i, issues: [] });
        else categoryIssues.push(i);
      });

      this.issues.forEach((i) => {
        var extractedIssues = categoryIssues.filter((ci) => {
          return ci.question === i.issue.question;
        });

        extractedIssues.forEach((ei) => {
          i.issues.push({ issue: ei, issues: [] });
        });
      });

      if (!this.initComplete) {
        this.initComplete = true;
        this.$emit("initCompleted");
      }
    },

    // Construct order issues
    constructOrderIssues() {
      this.issues.forEach((category) => {
        category.issue.category = null;
        category.issues.forEach((i) => {
          i.issue.category = category.issue.question;
        });
      });

      this.$emit("itemsMoved", this.issues);
    },

    // Update the issue in the issues array
    issueUpdated(issue) {
      this.$emit("issueUpdated", issue);
    },

    // Confirm deleting issue
    confirmIssueDelete(issue) {
      this.showDeleteModal(issue._id, issue.issue);
    },

    // Delete the order issue
    deleteIssue(issueID) {
      WidgetSettingsService.DeleteOrderIssue({ issueID });
    },

    // Show the delete modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the item
    async deleteItem() {
      this.deleteQuestion(this.deleteID);
      this.$emit("deleteOrderIssue", this.deleteID);
      this.closeModal();
    },

    // Issue has been selected
    showIssue(issue) {
      this.selectedIssue = issue;
      this.addIssue();
    },

    // Show the add issue modal
    addIssue() {
      this.showOptions.addIssue = true;
    },

    // Close all modals
    closeModal() {
      this.selectedIssue = {};
      this.confirmationFields.show = false;
      this.showOptions.addIssue = false;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.btn {
  float: right;
  margin-top: 1.5rem;
}
</style>