<template>
  <div :class="{ 'read-only': isReadOnly }">
    <!--START: Message Details-->
    <div class="message-details">
      <span class="message-time-wrapper"
        >{{ widgetLanguage.chat.messages.sentLabel }}
        <span class="message-time">
          {{ getMessageTime(message.timestamp) }}
        </span>
      </span>
      <span class="message-ai"
        >{{ widgetLanguage.chat.messages.aiLabel }}
        <svg
          :data-src="require('@/assets/images/icons/ai-sparkle.svg')"
          height="12px"
          width="12px"
          class="ai-icon"
        ></svg>
      </span>
    </div>
    <!--END: Message Details-->
    <div class="message" :class="message.messageType">
      <p>
        {{ widgetLanguage.chat.messages.customerPortal.message }}
      </p>
      <div class="options-wrapper">
        <button class="option-button">
          {{ widgetLanguage.chat.messages.customerPortal.buttonTitle }}
        </button>
      </div>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CustomerPortal",
  data() {
    return {
      isReadOnly: false,
    };
  },
  props: {
    message: { type: Object, required: true },
  },
  watch: {},
  components: {},
  computed: {
    // Widget language
    widgetLanguage() {
      return this.languages[this.$store.getters.widgetLanguage].widget;
    },
  },
  created() {},
  methods: {
    // Time for the message
    getMessageTime(responseTime) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      if (responseTime)
        return new Date(responseTime)
          .toLocaleTimeString([], options)
          .toUpperCase();
      return new Date().toLocaleTimeString([], options).toUpperCase();
    },
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/chat.scss";

.option-button {
  display: block;
  border: none;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.25rem;
  background: lighten($purpleColor, 10%);
  color: lighten($purpleColor, 40%);
  cursor: pointer;

  &:first-child {
    margin-top: 1rem;
  }
}
</style>