<template>
  <div v-if="!lodash.isEmpty(widget)" class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveWidget"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Form Blocks-->
      <div class="settings-form-wrapper">
        <SettingsForm
          v-for="field in fields"
          :key="field._id"
          :fields="field.fields"
          :content="field.content"
          @fieldChanged="fieldChanged"
        ></SettingsForm>
      </div>
      <!--END: Form Blocks-->

      <!--START: Order Issues-->
      <div class="settings-form card">
        <div class="settings-form-wrapper">
          <div class="content-wrapper">
            <h3>{{ orderIssuesFields.content.title }}</h3>
            <p>{{ orderIssuesFields.content.description }}</p>
          </div>

          <OrderIssues
            :orderIssues="orderIssues"
            @initCompleted="initFormData"
            @issueUpdated="updatedOrderIssue"
            @itemsMoved="constructOrderIssues"
            @deleteOrderIssue="deleteOrderIssue"
            class="suggested-questions"
          ></OrderIssues>
        </div>
        <div class="settings-form-wrapper order-actions-wrapper">
          <div class="content-wrapper">
            <h3>{{ orderActionsFields.content.title }}</h3>
            <p>{{ orderActionsFields.content.description }}</p>
          </div>
          <div class="form-builder">
            <div
              v-for="(action, key) in orderActionsFields.fields"
              :key="key"
              class="action-item"
            >
              <FormBuilder
                :fields="{ [key]: action }"
                @fieldChanged="fieldChanged"
                class="form"
              ></FormBuilder>
              <button
                v-if="action.showEditButton"
                class="btn btn-text btn-icon"
                @click="showActionRules(key)"
              >
                <unicon name="pen"></unicon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--END: Order Issues-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Action Rules-->
    <ActionRules
      v-if="showOptions.actionRules"
      :customerAccountPortal="widget.customerAccountPortal"
      :selectedAction="selectedAction"
    ></ActionRules>
    <!--END: Action Rules-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
            
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import FormBuilder from "@/components/form/FormBuilder";
import OrderIssues from "@/components/dashboard/widget/customerAccountPortal/Issues";
import ActionRules from "@/components/dashboard/widget/customerAccountPortal/ActionRules";
import SettingsForm from "@/components/form/SettingsForm";
import LineLoader from "@/components/loaders/LineLoader";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

export default {
  name: "ShopifyPortal",
  data() {
    return {
      lodash: _,
      dataPopulated: false,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        lineLoader: false,
        disableButton: true,
        actionRules: false,
      },
      regularHeader: {
        title: "Shopify Customer Portal",
        description: "Configure the Shopify portal for your customers",
      },
      fields: {
        settings: {
          content: {
            title: "Settings",
            description: "Control the settings of Shopify portal",
          },
          fields: {
            isActive: {
              type: "toggle",
              title: "Enable the portal",
              additionalClass: "large-toggle",
              value: true,
            },
            authentication: {
              type: "dropdown",
              placeholder: "Select authentication method",
              title: "Method to authenticate customer*",
              required: true,
              hasError: false,
              fields: [
                { name: "OrderID", value: "orderID" },
                { name: "Email OTP", value: "email" },
                { name: "Phone OTP", value: "phone" },
              ],
              value: [],
            },
          },
        },
      },
      orderIssuesFields: {
        content: {
          title: "Order Issues",
          description:
            "These issues and questions will be shown to customers when they managae a specific order",
        },
        fields: [],
      },

      orderActionsFields: {
        content: {
          title: "Order Actions",
          description:
            "These are actions that a customer can take while managing their orders",
        },
        fields: {
          trackOrder: {
            type: "toggle",
            title: "Allow order tracking",
            additionalClass: "large-toggle",
            value: false,
            showEditButton: false,
          },
          editShippingAddress: {
            type: "toggle",
            title: "Allow shipping address to be changed",
            additionalClass: "large-toggle",
            value: false,
            showEditButton: false,
          },
          cancelOrder: {
            type: "toggle",
            title: "Allow order cancellations",
            additionalClass: "large-toggle",
            value: false,
            showEditButton: true,
          },
        },
      },

      orderIssues: [],
      selectedAction: {},

      initData: {},
      dataChanged: false,
      status: {
        show: false,
        status: "success",
        title: "Shopify portal saved",
      },
    };
  },
  props: {
    widget: Object,
  },
  components: {
    RegularHeader,
    FormBuilder,
    OrderIssues,
    ActionRules,
    SettingsForm,
    LineLoader,
    UnsavedChangesModal,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  watch: {
    widget: {
      immediate: true,
      deep: true,
      handler() {
        this.populateData();
      },
    },
  },
  async created() {
    this.populateData();
  },
  methods: {
    // Save widget
    async saveWidget() {
      if (this.dataChanged) {
        // Check if the form has valid input
        let data = { ...this.fields.appearance.fields };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const formData = this.constructPayload(this.parseData(data));
          await this.$emit("saveWidget", formData);
          this.dataPopulated = false;
        }
      }
    },

    // Populate the data for the fields
    populateData() {
      if (!this.dataPopulated) {
        this.dataPopulated = true;

        const { fields } = this.fields.settings;
        const { customerAccountPortal } = this.widget;

        fields.isActive.value = customerAccountPortal.isActive;
        if (customerAccountPortal.authentication) {
          fields.authentication.value = [
            customerAccountPortal.authentication,
            customerAccountPortal.authentication,
          ];
        }

        this.initFormData();
      }
    },

    // Update the suggested questions
    updatedOrderIssue(question) {
      const tempIssues = _.cloneDeep(this.orderIssues);
      const index = tempIssues.findIndex((q) => q._id === question._id);
      if (index == -1) tempIssues.push(question);
      else tempIssues[index] = question;

      this.orderIssues.splice(0);
      this.suggesteorderIssuesQuestions = _.cloneDeep(tempIssues);
    },

    // Reconstruct messages after item move
    constructOrderIssues(issues) {
      const tempIssues = [];
      issues.forEach((i) => {
        tempIssues.push(i.issue);
        i.issues.forEach((issue) => {
          tempIssues.push(issue.issue);
        });
      });

      this.orderIssues.splice(0);
      this.orderIssues = _.cloneDeep(tempIssues);

      this.fieldChanged();
    },

    // Delete order issue
    deleteOrderIssue(issueID) {
      const index = this.orderIssues.findIndex((i) => i._id === issueID);
      this.orderIssues.splice(index, 1);
    },

    // Show the rules for the actions
    showActionRules(action) {
      this.selectedAction = action;
      this.showOptions.actionRules = true;
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields,
        orderIssues: this.orderIssues,
        orderAtions: this.orderActionsFields,
      };
      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Event when field changed
    fieldChanged() {
      const data = {
        ...this.fields,
        orderIssues: this.orderIssues,
        orderAtions: this.orderActionsFields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const widget = {
        selfServicePortal: data,
      };
      widget.selfServicePortal.suggestedQuestions = this.suggestedQuestions;

      // Create form data
      let formData = new FormData();
      for (var key in widget) formData.append(key, JSON.stringify(widget[key]));

      // Add thumbnail image
      const { thumbnailURL } = this.fields.appearance.fields;
      if (thumbnailURL.imagesList.length) {
        formData.append("images", thumbnailURL.imagesList[0]);
        formData.append("selfServiceIconAdded", true);

        delete widget.thumbnailURL;
      }

      return formData;
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.fields = _.cloneDeep(this.initData);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
            
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.suggested-questions {
  flex: 1;
}

.order-actions-wrapper {
  padding-top: 1.5rem;
}

.action-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;

  .btn {
    margin-left: 0.5rem;
  }

  /deep/ {
    .form {
      width: calc(100% - 2.5rem);
    }

    .field-main-item {
      margin-bottom: 0 !important;
    }
  }
}
</style>